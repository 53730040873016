<template>
  <div :class="props.class" style="position: relative; container-type: inline-size;">
    <div class="corner-borders outline-primary-500 p-4" :class="[props.innerClass, { 'corner-borders--small': props.small, 'corner-borders--1': props.size == 1 }]"></div>
    <slot></slot>
  </div>
</template>
<script setup>

const props = defineProps({
  class: String,
  innerClass: String,
  small: Boolean,
  size: Number | undefined
});

</script>
<style lang="postcss">
.corner-borders {

  @apply absolute left-0 right-0 top-0 bottom-0 z-[1] pointer-events-none;

  /* the size on the corner */
  --s: max(min(4cqw, 3rem), 0.6rem);
  /* the thickness of the border */
  --t: max(1.25cqw, 0.2rem);

  outline-width: min(var(--t), 0.6rem);
  outline-style: solid;
  outline-offset: calc(-1 * min(var(--t), 0.6rem));
  -webkit-mask:
    conic-gradient(at var(--s) var(--s), #0000 75%, #000 0) 0 0 / calc(100% - var(--s)) calc(100% - var(--s)),
    linear-gradient(#000 0 0) content-box;

  &--small {
    --s: max(min(2cqw, 3rem), 0.6rem);
    --t: max(0.7cqw, 0.2rem);
  }

  &--1 {
    --s: 1rem;
    --t: 0.3rem;
  }
}
</style>